<script>
import { required } from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import i18n from "@/i18n";

export default {
  page() {
    return {
      title: this.$t("login.login"),
    };
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      submitted: false,
      extendvoid: false,
      qrcodelogin: false,
      inputList: ["", "", "", "", "", ""],
      authcodes: "",
      keyboard: false,
      ismobile: false,
      loading: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  mounted() {
    document.body.classList.add("auth-body-bg");
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    var languagecode = navigator.language;
    languagecode = languagecode.substr(0, 2);
    i18n.locale = languagecode;
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      var that = this;
      that.submitted = true;
      // stop here if form is invalid
      that.$v.$touch();
      if (that.$v.$invalid) {
        return;
      } else {
        that.$axios
          .post("https://admin.aicoiot.beer/api.php", {
            action: "login",
            username: that.email,
            password: that.password,
          })
          .then(function (response) {
            if (response.data.status == 403) {
              that.toast(that.$t("login.needauth"));

              that.extendvoid = true;
              that.keyboard = true;
              that.isAuthError = true;
              that.authError = error ? error : "";

              document.getElementsByClassName("border-input")[0].focus();
            } else if (response.data.status == 200) {
              localStorage.setItem("user", JSON.stringify(response.data.data));
              that.tryingToLogIn = false;
              that.isAuthError = false;
              that.$router.push(
                that.$route.query.redirectFrom || { name: "home" }
              );
            } else {
              that.toast(that.$t("login.loginerror"));
              that.isAuthError = true;
              that.authError = error ? error : "";
            }
          })
          .catch(function (error) {});
      }
    },
    tryToLogInExtend() {
      if (!this.ismobile) {
        var authcode = this.inputList.join("");
      } else {
        var authcode = this.authcodes;
      }
      var that = this;
      that.submitted = true;
      // stop here if form is invalid
      that.$v.$touch();

      if (that.$v.$invalid) {
        return;
      } else {
        that.loading = true;
        that.$axios
          .post("https://admin.aicoiot.beer/api.php", {
            action: "login",
            username: that.email,
            password: that.password,
            authkey: authcode,
          })
          .then(function (response) {
            that.loading = false;
            if (response.data.status == 403) {
              that.toast(that.$t("login.needauth"));
              that.isAuthError = true;
              that.authError = error ? error : "";
            } else if (response.data.status == 200) {
              localStorage.setItem("user", JSON.stringify(response.data.data));
              that.tryingToLogIn = false;
              that.isAuthError = false;
              that.$router.push(
                that.$route.query.redirectFrom || { name: "home" }
              );
            } else {
              that.toast(that.$t("login.loginerror"));
              that.isAuthError = true;
              that.authError = error ? error : "";
            }
          })
          .catch(function (error) {});
      }
    },
    nextFocus(el, index) {
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(el.key) || el.keyCode == 8) {
        var dom = document.getElementsByClassName("border-input"),
          currInput = dom[index],
          nextInput = dom[index + 1],
          lastInput = dom[index - 1];
        /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/
        if (el.keyCode != 8) {
          this.inputList[index] = el.key;

          if (index < this.inputList.length - 1) {
            nextInput.focus();
          } else {
            currInput.blur();
            this.tryToLogInExtend();
          }
        } else {
          this.inputList[index] = "";
          if (index != 0) {
            lastInput.focus();
          }
        }
      }
    },
    /*当键盘按下的时候清空原有的数*/
    changeValue(index) {
      this.inputList[index] = "";
    },
    toast(text, append = false) {
      this.counter++;
      this.$bvToast.toast(text, {
        title: this.$t("login.alter"),
        toaster: "b-toaster-top-center",
        solid: true,
        appendToast: append,
      });
    },
    onInput(key) {
      this.authcodes = (this.authcodes + key).slice(0, 6);
      if (this.authcodes.length == 6) {
        this.tryToLogInExtend();
      }
    },
    onDelete() {
      this.authcodes = this.authcodes.slice(0, this.authcodes.length - 1);
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/mainlogo_darkgree_web.png"
                              width="100%"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          {{ $t("login.login") }}
                        </h4>
                        <p class="text-muted" v-if="extendvoid">
                          {{ $t("login.inputcode") }}
                        </p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >

                      <div class="p-2 mt-5" v-if="!extendvoid && !qrcodelogin">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="username">{{
                              $t("login.username.text")
                            }}</label>
                            <input
                              type="text"
                              v-model="email"
                              class="form-control"
                              id="username"
                              :placeholder="$t('login.username.placeholder')"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required">{{
                                $t("login.username.noinput")
                              }}</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">{{
                              $t("login.password.text")
                            }}</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              :placeholder="$t('login.password.placeholder')"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              {{ $t("login.password.noinput") }}
                            </div>
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >{{ $t("login.remember") }}</label
                            >
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              {{ $t("login.login") }}
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <!-- <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i>
                              {{ $t("login.forgot") }}
                            </router-link> -->
                          </div>
                        </form>
                      </div>
                      <!--验证码输入-->
                      <div
                        class="p-2 mt-5"
                        v-if="extendvoid && !qrcodelogin"
                        v-loading="loading"
                      >
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogInExtend"
                        >
                          <div class="input-box" v-if="!ismobile">
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="
                                width: 16%;
                                float: left;
                                text-align: center;
                              "
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 0)"
                            />
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="
                                width: 16%;
                                float: left;
                                text-align: center;
                              "
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 1)"
                            />
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="
                                width: 16%;
                                float: left;
                                text-align: center;
                              "
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 2)"
                            />
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="
                                width: 16%;
                                float: left;
                                text-align: center;
                              "
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 3)"
                            />
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="
                                width: 16%;
                                float: left;
                                text-align: center;
                              "
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 4)"
                            />
                            <input
                              class="form-control border-input"
                              oninput="value=value.replace(/\D/g,'')"
                              style="width: 16%; text-align: center"
                              maxlength="1"
                              type="tel"
                              @keyup="nextFocus($event, 5)"
                            />
                          </div>
                          <div class="input-box" v-if="ismobile">
                            <!-- 密码输入框 -->
                            <van-password-input
                              :value="authcodes"
                              :length="6"
                              :mask="false"
                              :focused="keyboard"
                              @focus="keyboard = true"
                            />
                            <!-- 数字键盘 -->
                            <van-number-keyboard
                              :show="keyboard"
                              @input="onInput"
                              @delete="onDelete"
                              @blur="keyboard = false"
                            />
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <!-- <p>
                          {{ $t("login.noaccount") }}
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                            >{{ $t("login.register") }}</router-link
                          >
                        </p> -->
                        <p>
                          © 2016 Shijiazhuang MOE Information Technology Co.,
                          Ltd<br />
                          © 2015 AICO Group Limited company<br />
                          © 2021 AICO CLOUD UK LTD
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>